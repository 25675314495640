import React from 'react'

export default function footer() {
    return (
        <footer>
            <div className='contact' id='contacts'>
                <h2 className='title'>Контакты</h2>
                <div className='contact-block'>
                    <div className='contact-block-sharp'>
                        <div className='contact-block-item-1'>Телефон отдела персонала</div>
                        <div className='contact-block-item-2'>+7 (925) 386 79-21</div>
                    </div>
                    <div className='contact-block-sharp'>
                        <div className='contact-block-item-1'>E-mail</div>
                        <div className='contact-block-item-2'>hr@anzhee.ru</div>
                    </div>
                    <div className='contact-block-sharp'>
                        <div className='contact-block-item-1'>Адрес офиса</div>
                        <div className='contact-block-item-2'>г. Москва, пр. Завода Серп и Молот, 6к1</div>
                    </div>
                    <div className='contact-block-sharp'>
                        <div className='contact-block-item-1'>Адрес склада</div>
                        <div className='contact-block-item-2'>г. Москва, ул. Красная Сосна, 2к1с1</div>
                    </div>
                </div>
            </div>

        </footer>
    )
}
