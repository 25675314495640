import React from 'react'

export default function Events() {
  return (
    <div className='events'>
        <h2 className='title'>Наша команда</h2>
        <div className='events-block'>
            <div className='events-sharp'>
                <div className='events-item'>
                    <div className='events-item-border'>
                        <img src="./img/events/events_1.jpg" alt = 'events_1'></img>
                    </div>
                </div>
                <div className='events-item'>
                    <div className='events-item-border'>
                        <img src="./img/events/events_2.jpg" alt = 'events_2'></img>
                    </div>
                </div>
                <div className='events-item'>
                    <div className='events-item-border'>
                        <img src="./img/events/events_6.jpg" alt = 'events_3'></img>
                    </div>
                </div>
                <div className='events-item'>
                    <div className='events-item-border'>
                        <img src="./img/events/events_4.jpg" alt = 'events_4'></img>
                    </div>
                </div>
                <div className='events-item'>
                     <div className='events-item-border'>
                        <img src="./img/events/events_8.jpg" alt = 'events_5'></img>
                    </div>
                </div>
                <div className='events-item'>
                    <div className='events-item-border'>
                        <img src="./img/events/events_5.jpg" alt = 'events_6'></img>
                    </div>
                </div>
                <div className='events-item'>
                    <div className='events-item-border'>
                        <img src="./img/events/events_9.jpg" alt = 'events_7'></img>
                    </div>
                </div>
                <div className='events-item'>
                    <div className='events-item-border'>
                        <img src="./img/events/events_7.jpg" alt = 'events_8'></img>
                    </div>
                </div>
            </div>
            

        </div>    
    </div>
  )
}
