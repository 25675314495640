import React from 'react'

export default function Selection() {
  return (
    <div className='selection'>
        <h2 className='title'>Этапы отбора</h2>
        <div className='selection-sharp'>
            <div className='selection-block'>
                <a className='selection-number'>01</a>
                <a className='selection-article'>Резюме</a>
                <a className='selection-text'>
                    Пришлите нам резюме и незабудьте подчеркнуть в нём собственную индивидуальность.
                </a>
            </div>
            <div className='selection-block'>
                <a className='selection-number'>02</a>
                <a className='selection-article'>Интервью с HR</a>
                <a className='selection-text'>
                    Пройдя предварительное интервью с нашим HR-специалистом, Вы узнаете больше о нашей компании и желаемой должности, а мы узнаем больше о Вас.
                </a>
            </div>
            <div className='selection-block'>
                <a className='selection-number'>03</a>
                <a className='selection-article'>Интервью с Руководителем</a>
                <a className='selection-text'>
                    На собеседовании с руководителем Вы сможете обсудить предстоящие задачи, узнать над чем Вам предстоит работать в нашей компании и какие цели перед Вами будут стоять. Так же Вы сможете лучше узнать своего руководителя, рассказать о себе и раскрыть свой потенциал.
                </a>
            </div>
            <div className='selection-block'>
                <a className='selection-number'>04</a>
                <a className='selection-article'>Job-оффер</a>
                <a className='selection-text'>
                    Вы приняты, добро пожаловать в команду! С нами ещё один классный специалист!
                </a>
            </div>
        </div>  
      </div>
      
  )
}
