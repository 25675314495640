import React, { useState } from 'react'
import { FaBars } from "react-icons/fa6"
import {Link} from 'react-scroll'

export default function Header() {
    const [isOpen, setOpen] = useState();
    return (
        <header>
            <div className='top-header'>
                <div className = 'logo'>
                    <a href = 'https://anzhee.ru/' target="_blank">
                        <img src = './img/ANZHEE Light&Sound Equipment_ Full White.png' alt='logo'></img>
                    </a>
                </div>
                <div className={`menu ${isOpen ? 'active' : ''}`}>
                    <ul className = 'nav'>
                        <li>
                            <a href='#about'>О компании</a>
                        </li>
                        <li>
                            <a href='#form'>Анкета</a>
                        </li>
                        <li>
                            <a href='#contacts'>Контакты</a>
                        </li>
                    </ul>
                </div>
                <FaBars className='menu-button' onClick={()=> setOpen(!isOpen)}  />
            </div>
            
            <div className = 'presentation'>
                <div className='presentation-text'>
                    <h2>МЕЧТАЕШЬ О СТАБИЛЬНОЙ РАБОТЕ С ИНТЕРЕСНЫМИ УСЛОВИЯМИ?</h2>
                    <h1>Стань</h1>
                    <h1>частью команды</h1>
                    <h3>
                        Помогаем специалистам шоу-индустрии, деятелям культуры и искусства делать людей счастливее и способствовать их духовному развитию
                    </h3>
                    <div className='presentation-form-button'><a href='#form'>Заполнить анкету</a></div>
                </div>
                <div className='presentation-img'>
                    <img src="./img/photo_team_1.png"></img>
                </div>
            </div>
        </header>
  )
}
