import React from 'react'

export default function Advantages() {
  return (
    <div className='advantages'>
        <h2 className='title'>Наши преимущества</h2>
        <div className='advantages-sharp'>
            <div className='advantages-item'>
                <h2>Стабильность</h2>
                <p>
                    Компания Anzhee на протяжении всего существования планомерно и последовательно продвигается вперед, покоряя всё новые вершины. Многие специалисты работают в Anzhee более 3 лет, а некоторые - со времени основания компании
                </p>
            </div>

            <div className='advantages-item'>
                <h2>Забота о сотрудниках</h2>
                <p>
                    Работа в белую, ЗП без задержек с ежегодной индексацией, сногшибательные копрпоративы и различные внерабочие активности - мы делаем всё, чтобы у нас каждый чувствовал себя комфортно и на своём месте
                </p>
            </div>

            <div className='advantages-item'>
                <h2>Развитие</h2>
                <p>
                    Наша компания постоянно растет, а вместе с ней развивается и масштабируется команда. Многие наши сотрудники прошли профессиональный путь и выросли в должности, возглавляя департаменты и отделы. Постоянно открываются новые направления и проекты, где Вы сможете проявить себя и получить опыт
                </p>
            </div>

            <div className='advantages-item'>
                <h2>Команда</h2>
                <p>
                    Ключевой ресурс нашей компании - сплочённая команда, а её основы - уважительное отношение друг к другу, взаимопомощь, желание, что бы каждый мог раскрыться в том, в чём он талантливее и чему более склонен. Мы поддерживаем в коллективке дружескую, непринужденную атмосферу и преодолеваем все трудности вместе
                </p>
            </div>

            <div className='advantages-item'>
                <h2>Образовательный фонд</h2>
                <p>
                    Нами организованно всё необходимое, чтобы помочь желающим получить новые знания: возможность посещать проф. курсы, внутреннее обучение от Департамента продукта и т.д. Также в распоряжении сотрудников компании целый образовательный фонд, в рамках которого можно пройти стороннее обучение, система рагламентов для быстрой адаптации, специально подобранная электронная библиотека, много других полезных материалов
                </p>
            </div>
        </div>
    </div>
  )
}
