import React from "react"
import Header from "./components/Header";
import About from "./components/About";
import Rules from "./components/Rules";
import Footer from "./components/Footer"
import Advantages from "./components/Advantages";
import Events from "./components/Events";
import Selection from "./components/Selection";
import Feedback from "./components/Feedback";
import Form from "./components/Form";
import Media from "./components/Media";



function App() {
  return (
    <div className = 'wrapper'>
        <div className = 'container'>
          <Header />
          <About />
          <Rules />
          <Advantages />
          <Events />
          <Selection />
          <Feedback />
          <Form />
          <Media />
          <Footer />
            
        </div>
    </div>
  );
}

export default App;
