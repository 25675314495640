import React from 'react'

export default function Media() {
    return (
        <div className='media'>
            <h2 className='title'>Медиа</h2>
            <div className='media-block'>
                <iframe className='media-block-frame' width='100%' height='100%' src="https://www.youtube.com/embed/EnN-WDBJBGs?si=sCJbF202aELNFpiz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> 
            </div>
        </div>
  )
}
