import React from 'react'
import { FaStar } from "react-icons/fa6";
import { FaStarHalf } from "react-icons/fa6";

export default function Feedback() {
  return (
    <div className='feedback'>
        <h2 className='title'>Отзывы от сотрудников</h2>
        <div className='feedback-sharp'>
            <div className='feedback-item'>
                <div className='feedback-top'>
                    <p>Август 2023</p>
                    <div className='feedback-star'>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                    </div>
                </div>
                <a>
                Отличная , активно растущая компания. Удобный, современный и красивый офис в пешей доступности от метро.
Дружная атмосфера среди коллег и адекватное руководство. Много разных, интересных и новых задач, которые не дадут провалиться в рутину. Компания заинтересована в профессиональном росте сотрудников.
                </a>
                <h2>Графический дизайнер</h2>
            </div>
            <div className='feedback-item'>
                <div className='feedback-top'>
                    <p>Сентябрь 2023</p>
                    <div className='feedback-star'>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStarHalf />
                    </div>
                </div>
                <a>
                Лояльное и адекватное руководство, приятные бонусы и корпоративы. Зп полностью белая и без задержек.
                </a>
                <h2>Офис-менеджер</h2>
            </div>
            <div className='feedback-item'>
                <div className='feedback-top'>
                    <p>Ноябрь 2023</p>
                    <div className='feedback-star'>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                    </div>
                </div>
                <a>
                Хорошая компания, дружный коллектив. От компании устраиваются выездные мероприятия для сотрудников.
Красивый уютный офис почти в центре, поэтому добираться удобно.
Раньше штат был не большой поэтому было немного сложно, но сейчас компания растет и сотрудников стало в разы больше, что не может не радовать.
                </a>
                <h2>Офис-менеджер</h2>
            </div>
            <div className='feedback-item'>
                <div className='feedback-top'>
                    <p>Декабрь 2023</p>
                    <div className='feedback-star'>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                    </div>
                </div>
                <a>
                Отличная компания. Отличный коллектив.
Удобно расположен офис в Москве.
ЗП белая, без задержек. Индексация ЗП и премиальной части.
Выставки и различные выездные мероприятия.
Возможность карьерного роста.
                </a>
                <h2>Продукт-менеджер</h2>
            </div>
        </div>
    </div>
  )
}
